var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-1 sidebar-header"},[_c('b-button',{staticClass:"btn-icon text-dark",attrs:{"variant":"flat-success"},on:{"click":_vm.hide}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1),_c('h4',{attrs:{"id":"sidebar-no-header-title text-dark pl-1"}},[_vm._v(" Opções do card ")])],1),_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":"sm","variant":_vm.variant}},[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""},on:{"mouseover":function($event){_vm.mouseOver = true},"mouseout":function($event){_vm.mouseOver = false}}},[_c('b-card-body',[_c('b-tabs',[(_vm.card.column !== 'finished'
              && (
                _vm.card.role === 'success'
                && ['onboarding', 'following', 'follow_up', 'new_subscriber', 'future_schedule', 'churn'].find(function (f) { return f === _vm.card.column; })
              ) || (
                _vm.card.role === 'sales'
              ) || (
                _vm.card.role === 'cerc'
              ) || (
                _vm.card.role === 'sales_sdr'
              ) || (
                _vm.card.role === 'relationship'
                && ['schedule', 'following', 'follow_up', 'onboarding', 'transition'].find(function (f) { return f === _vm.card.column; })
              ) || (
                _vm.card.role === 'service_qualification'
                && ['double', 'active_review', 'smarties'].find(function (f) { return f === _vm.card.column; })
              ) || (
                !!_vm.card.additional
                && _vm.card.column === 'sanitation'
              ) || (
                !!_vm.card.additional
                && _vm.card.column === 'issue_success'
              ) || (
                _vm.card.role === 'mentoring'
              ) || (
                !!_vm.card.additional
                && _vm.card.column === 'cerc_assistance'
              )
            )?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v("Ações")])]},proxy:true}],null,false,3219558413)},[(
                _vm.card.role === 'service_qualification'
              )?_c('div',[(['smarties'].find(function (f) { return f === _vm.card.column; }))?_c('b-row',[_c('b-col',[_c('p',[_vm._v(" Marcar o usuário como falso positivo até a próxima alteração do perfil: ")]),_c('div',{staticClass:"text-right mt-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleRegisterContact}},[_vm._v(" Falso positivo ")])],1)])],1):_vm._e(),(['double'].find(function (f) { return f === _vm.card.column; }))?_c('b-row',[_c('b-col',[_c('p',[_vm._v(" Após finalizar a migração do usuário clique no botão abaixo: ")]),_c('div',{staticClass:"text-right mt-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleRegisterContact}},[_vm._v(" Finalizar ")])],1)])],1):_vm._e(),(['active_review'].find(function (f) { return f === _vm.card.column; }))?_c('b-row',[_c('b-col',[_c('p',[_vm._v(" Após finalizar a revisão do usuário clique no botão abaixo: ")]),_c('div',{staticClass:"text-right mt-1"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.handleRegisterContact}},[_vm._v(" Finalizar ")])],1)])],1):_vm._e()],1):(_vm.card.additional && ['sanitation', 'issue_success'].includes(_vm.card.column))?_c('div',[_c('b-form',[_c('validation-observer',{ref:"rulesAdditionalContactMade"},[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.options},model:{value:(_vm.contactMade),callback:function ($$v) {_vm.contactMade=$$v},expression:"contactMade"}})],1)],1),(_vm.contactMade === 'yes' && _vm.card.column === 'sanitation')?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Atuação"}},[_c('b-form-select',{attrs:{"options":_vm.sanitationCustomerStatusOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.sanitationCustomerStatus),callback:function ($$v) {_vm.sanitationCustomerStatus=$$v},expression:"sanitationCustomerStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3232712283)})],1),(_vm.sanitationCustomerStatus === 'active_interested')?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Enviar link da mensagem por"}},[_c('b-form-checkbox-group',{attrs:{"options":_vm.sanitationCustomerStatusActiveInterestedOptions,"switches":"","state":errors.length > 0 ? false:null},model:{value:(_vm.channelsToSend),callback:function ($$v) {_vm.channelsToSend=$$v},expression:"channelsToSend"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2822468558)})],1):_vm._e()],1):(_vm.contactMade === 'no')?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Motivo"}},[_c('b-form-select',{attrs:{"options":_vm.sanitationCustomerNoContactReasonOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.sanitationCustomerNoContactReason),callback:function ($$v) {_vm.sanitationCustomerNoContactReason=$$v},expression:"sanitationCustomerNoContactReason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,937207575)})],1)],1):_vm._e(),(_vm.showAnnotationField)?_c('validation-provider',{attrs:{"rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Anotação","label-for":"annotation"}},[_c('b-form-textarea',{attrs:{"id":"annotation","rows":"6","state":errors.length > 0 ? false:null},model:{value:(_vm.annotation),callback:function ($$v) {_vm.annotation=$$v},expression:"annotation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,45025566)}):_vm._e(),(_vm.showScheduleDateField)?_c('div',{staticClass:"mt-2"},[(!_vm.showScheduleWithAgendador)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Selecione a data","label-for":"new_date"}},[_c('flat-pickr',{ref:"datePicker",staticClass:"form-control",attrs:{"id":"schedule_date","config":{
                            inline:true,
                            allowInput:false,
                            position: 'auto center',
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:S',
                            altFormat: 'd/m \\à\\s H:i',
                            altInput: true,
                            minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                            time_24hr: true,
                          }},model:{value:(_vm.dateNtim),callback:function ($$v) {_vm.dateNtim=$$v},expression:"dateNtim"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,792251543)}):_vm._e(),(_vm.showScheduleWithAgendador)?_c('div',[_c('validation-observer',{ref:"rulesDateTime"},[_c('b-form-group',{attrs:{"label":"Selecione a data","label-for":"new_date"}},[_c('b-form-input',{attrs:{"value":_vm.selectedDate,"disabled":""}}),(_vm.isLoadingEvent)?_c('div',{staticClass:"text-center mt-2"},[_c('b-spinner'),_c('p',[_vm._v("Carregando horários...")])],1):_c('flat-pickr',{ref:"datePicker",staticClass:"form-control",attrs:{"id":"schedule_date","config":{
                              enable: _vm.enableDates,
                              inline:true,
                              allowInput:false,
                              enableTime: false,
                              position: 'auto center',
                              dateFormat: 'Y-m-d',
                              altFormat: 'd/m',
                              altInput: true,
                              altInputClass: 'd-none',
                              minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                              time_24hr: true,
                            }},model:{value:(_vm.dateNtim),callback:function ($$v) {_vm.dateNtim=$$v},expression:"dateNtim"}})],1),_c('validation-provider',{attrs:{"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Horário"}},[_c('b-form-select',{attrs:{"id":"time","options":_vm.getTimesToTour,"state":errors.length > 0 ? false:null},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,295478329)})],1)],1):_vm._e()],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right mt-1"},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.contactMade},on:{"click":_vm.handleAdditionalRegisterContact}},[_vm._v(" Salvar ")])],1)],1)],1):_c('b-form',[_c('validation-observer',{ref:"rulesForm"},[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.options,"name":"radio-validation"},model:{value:(_vm.contactMade),callback:function ($$v) {_vm.contactMade=$$v},expression:"contactMade"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[(_vm.showDescriptionField)?_c('validation-provider',{attrs:{"rules":_vm.rulesDescriptionField},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição do card","label-for":"description"}},[_c('b-form-textarea',{attrs:{"id":"description","rows":"6","state":errors.length > 0 ? false:null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4076080153)}):_vm._e(),(_vm.showScheduleDateField)?_c('div',[(!_vm.showScheduleWithAgendador)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Selecione a data","label-for":"new_date"}},[_c('flat-pickr',{ref:"datePicker",staticClass:"form-control",attrs:{"id":"schedule_date","config":{
                              inline:true,
                              allowInput:false,
                              position: 'auto center',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                              altFormat: 'd/m \\à\\s H:i',
                              altInput: true,
                              minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                              time_24hr: true,
                            }},model:{value:(_vm.dateNtim),callback:function ($$v) {_vm.dateNtim=$$v},expression:"dateNtim"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2039826327)}):_vm._e(),(_vm.showScheduleWithAgendador)?_c('div',[_c('validation-observer',{ref:"rulesDateTime"},[_c('b-form-group',{attrs:{"label":"Selecione a data","label-for":"new_date"}},[_c('b-form-input',{attrs:{"value":_vm.selectedDate,"disabled":""}}),(_vm.isLoadingEvent)?_c('div',{staticClass:"text-center mt-2"},[_c('b-spinner'),_c('p',[_vm._v("Carregando horários...")])],1):_c('flat-pickr',{ref:"datePicker",staticClass:"form-control",attrs:{"id":"schedule_date","config":{
                                enable: _vm.enableDates,
                                inline:true,
                                allowInput:false,
                                position: 'auto center',
                                dateFormat: 'Y-m-d',
                                altFormat: 'd/m \\à\\s',
                                altInput: true,
                                altInputClass: 'd-none',
                                minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                                time_24hr: true,
                              }},model:{value:(_vm.dateNtim),callback:function ($$v) {_vm.dateNtim=$$v},expression:"dateNtim"}})],1),_c('validation-provider',{attrs:{"rules":"required|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Horário"}},[_c('b-form-select',{attrs:{"id":"time","options":_vm.getTimesToTour,"state":errors.length > 0 ? false:null},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,295478329)})],1)],1):_vm._e()],1):_vm._e()],1)],1),(_vm.contactMade === 'yes' || _vm.contactMade === 'new' || _vm.contactMade === 'future-schedule' || (_vm.card.role === 'mentoring' && _vm.contactMade === 'schedule'))?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"rulesContactMade"},[(!_vm.showDemographics && _vm.showAnnotationField)?_c('validation-provider',{attrs:{"rules":_vm.card.role === 'sales' ? 'min:10' : 'required|min:10'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Observação","label-for":"annotation"}},[_c('b-form-textarea',{attrs:{"id":"annotation","rows":"6","state":errors.length > 0 ? false:null},model:{value:(_vm.annotation),callback:function ($$v) {_vm.annotation=$$v},expression:"annotation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3677912979)}):_vm._e(),(_vm.showBiasField)?_c('validation-provider',{attrs:{"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Viés","label-for":"type"}},[_c('b-form-select',{attrs:{"id":"bias","options":_vm.annotationBiasOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.annotationBias),callback:function ($$v) {_vm.annotationBias=$$v},expression:"annotationBias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2256418280)}):_vm._e(),(_vm.showChannelField)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Forma de Contato","label-for":"channel"}},[_c('b-form-select',{attrs:{"id":"channel","options":_vm.listAnnotationChannels,"state":errors.length > 0 ? false:null},model:{value:(_vm.annotationChannel),callback:function ($$v) {_vm.annotationChannel=$$v},expression:"annotationChannel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3987585975)}):_vm._e(),(['cerc'].includes(_vm.card.role) && _vm.contactMade === 'yes' && (_vm.card.column !== 'scheduled' || !_vm.isActivePlan))?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.cercShowContactMadeOptions())?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.roleCercContactOptions,"name":"salesContact"},on:{"change":_vm.changeSalesContact},model:{value:(_vm.salesContact),callback:function ($$v) {_vm.salesContact=$$v},expression:"salesContact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)],1)],1)]}}],null,false,2391487891)}):_vm._e()],1)],1):_vm._e(),((
                          _vm.card.role === 'sales'
                          || (_vm.card.role === 'success' && _vm.card.column === 'churn')
                        ) && _vm.contactMade === 'yes')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Qualificação do lead","label-for":"lead_qualification"}},[_c('b-form-input',{staticClass:"d-none",model:{value:(_vm.salesLeadQualification),callback:function ($$v) {_vm.salesLeadQualification=$$v},expression:"salesLeadQualification"}}),_c('b-dropdown',{staticClass:"full-width",attrs:{"id":"lead_qualification","variant":"outline-secondary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.salesLeadQualification === '')?_c('span',[_vm._v("Selecione")]):_vm._l((parseInt((_vm.salesLeadQualification || _vm.card.sales_lead_qualification || '0').replace(/[^0-9]/g, ''), 10)),function(star){return _c('feather-icon',{key:star,attrs:{"icon":"StarIcon","fill":"yellow","stroke":"gold"}})})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){_vm.salesLeadQualification = ''}}},[_vm._v(" Selecione ")]),_vm._l((3),function(rating){return _c('b-dropdown-item',{key:rating,on:{"click":function($event){_vm.salesLeadQualification = ''+rating}}},_vm._l((rating),function(star){return _c('feather-icon',{key:star,attrs:{"icon":"StarIcon","fill":"yellow","stroke":"gold"}})}),1)})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,961142094)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"9"}},[_c('b-form-group',[_c('b-form-radio-group',{staticClass:"demo-inline-spacing",attrs:{"options":_vm.roleSalesContactOptions,"name":"salesContact"},on:{"change":_vm.changeSalesContact},model:{value:(_vm.salesContact),callback:function ($$v) {_vm.salesContact=$$v},expression:"salesContact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)],1)],1)]}}],null,false,3457182188)}),(_vm.card.role === 'sales' && _vm.salesContact !== 'no')?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pipeline","label-for":"pipeline"}},[_c('b-form-select',{attrs:{"id":"pipeline","options":_vm.roleSalesPipelines},model:{value:(_vm.salesPipeline),callback:function ($$v) {_vm.salesPipeline=$$v},expression:"salesPipeline"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,791683660)}):_vm._e()],1)],1):_vm._e(),(_vm.card.role === 'sales_sdr' && _vm.contactMade === 'yes')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Qualificação do lead","label-for":"sdr_lead_qualification"}},[_c('b-form-select',{attrs:{"id":"sdr_lead_qualification","options":_vm.roleSalesSdrQualification,"state":errors.length > 0 ? false:null},model:{value:(_vm.leadQualification),callback:function ($$v) {_vm.leadQualification=$$v},expression:"leadQualification"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,13236072)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tem interesse no uso","label-for":"sdr_lead_use_interest"}},[_c('b-form-select',{attrs:{"id":"sdr_lead_use_interest","options":_vm.roleSalesSdrLeadInterest,"state":errors.length > 0 ? false:null},model:{value:(_vm.leadUseInterest),callback:function ($$v) {_vm.leadUseInterest=$$v},expression:"leadUseInterest"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2591792182)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-radio-group',{staticClass:"demo-inline-spacing d-flex flex-column align-items-start",attrs:{"options":_vm.roleSalesSdrContactOptions,"name":"salesContact"},model:{value:(_vm.salesContact),callback:function ($$v) {_vm.salesContact=$$v},expression:"salesContact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3887506943)})],1)],1):_vm._e(),(_vm.showAssignToField)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Atribuir a","label-for":"assign_to"}},[_c('b-form-select',{attrs:{"id":"assign_to","options":_vm.roleRelationshipAnnotationAssignTo,"state":errors.length > 0 ? false:null},model:{value:(_vm.annotationAssignTo),callback:function ($$v) {_vm.annotationAssignTo=$$v},expression:"annotationAssignTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,720543407)}):_vm._e(),(_vm.showNewDateField)?_c('validation-provider',{attrs:{"rules":_vm.card.role === 'sales' ? 'required' : undefined},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nova data de contato","label-for":"new_date"}},[_c('flat-pickr',{ref:"datePicker",staticClass:"form-control",attrs:{"id":"new_date","config":Object.assign({}, {inline:true,
                              allowInput:false,
                              position: 'auto center',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i:S',
                              altFormat: 'd/m \\à\\s H:i',
                              altInput: true,
                              minDate: new Date().setMinutes( Math.floor((new Date()).getMinutes() / 5) * 5 ),
                              time_24hr: true},
                              _vm.card.role === 'sales' ? {
                                maxDate: new Date().setDate( (new Date()).getDate() + 30 ),
                              } : {})},model:{value:(_vm.dateNtim),callback:function ($$v) {_vm.dateNtim=$$v},expression:"dateNtim"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3188885176)}):_vm._e()],1)],1)],1):_vm._e(),(_vm.showDemographics)?_c('b-row',[_c('b-col',[_c('hr')]),_c('b-col',{attrs:{"cols":"12"}},[_c('user-list-demographic',{ref:"userListDemographic",attrs:{"save-button":false,"no-padding":true,"user-id":_vm.card.user_id,"user-type":_vm.card.user.type,"user-responsible":_vm.card.user.name,"sidebar-role":_vm.card.column === 'cerc_assistance' ? 'success' : _vm.card.role},on:{"isLoading":_vm.demographicIsLoading}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"text-right mt-1"},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.contactMade || _vm.isLoadingEvent || (_vm.showDemographics && _vm.isLoadingDemographics)},on:{"click":_vm.handleRegisterContact}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1):_vm._e(),(!_vm.simple)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',[_vm._v("Anotações")])]},proxy:true}],null,false,2030432510)},[_c('div',{staticClass:"activity-items"},[_c('user-list-annotation',{attrs:{"name":"userListAnnotation","user-id":_vm.card.user_id,"manager-id":_vm.card.account_manager_id,"small":""}})],1)]):_vm._e(),(!_vm.simple)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ActivityIcon"}}),_c('span',[_vm._v("Atividades")])]},proxy:true}],null,false,431628141)},[_c('div',{staticClass:"activity-items"},[_c('user-list-activities',{ref:"sidebarUserListActivities",attrs:{"name":"sidebarUserListActivities","user":_vm.card.user,"user-id":_vm.card.user_id,"small":true}})],1)]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }